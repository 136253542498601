<template>
	<div class="shops">
		<h5 class="biaoqi" v-if="proData.title">首页 > {{proData.title}}</h5>
		<h5 class="biaoqi" v-else>首页 > 等保测评+整改服务</h5>
		<div class="contains">
			<div class="con_top">
				<img class="con_top_left" v-if="proData.url" style="width:358px" :src="proData.url">
				<img class="con_top_left" v-else style="width:358px" src="../assets/images/dengbaozhengming/a05.png">
				<div class="con_top_right">
					<h2 v-if="proData.title">{{proData.title}}</h2>
					<h2 v-else>等保测评+整改服务</h2>
					<p class="new_tit">{{proData.cc}}</p>
					 <div class="pri_box">
					    <p class="title">产品价格<i>￥</i><span class="price">{{proData.price}}</span></p>
                    </div>
                     <div class="price_box">
                        <div class="box_left">
                            <p>说</p><p>明</p>
                        </div>
                        <div class="box_right">
                            <p>
                                {{proData.content}}
                            </p>
                        </div>
					</div>
					<div class="box_ti">
                        <div class="ti_left">
                            <p>数</p><p>量</p>
                        </div>
						<input class="numbers" value="1" type="text" name="">
					</div>
          				
					<div class="box_bottom">
						<!-- <a style="pointer-events: none;background:gray; " href="">立即购买</a> -->
						<a href="http://p.qiao.baidu.com/cps/chat?siteId=11551960&userId=24901984&siteToken=323624991236a4563e29b4ce63e29203">咨询专家</a>
					</div>
				</div>



			</div>
		</div>
	</div>
</template>
<script type="text/javascript">
export default{
	props:{
      proData:{
          type:Object,
          default:null
      }
	},
	data(){
		return{

		}
	},
	mounted(){
		console.log(this.proData)
	}
};


</script>

<style type="text/css" scoped  lang="less">
.shops{


.biaoqi{
	line-height: 32px;
	font-size:14px;
	padding-left:20px;
	color:#474747;
}
.contains{
	padding:20px;
	background: #fff;
}
.con_top{
	overflow:hidden;
}
.con_top_left{
	float:left;
	width:358px;
}

.con_top_right{
	float: right;
	width:750px
}
.con_top_right h2{
	font-size: 25px;
	font-weight: 300;
	line-height: 42px;
	font-weight: bold;
}
.new_tit{
	background:#0E47B8;
	line-height: 22px;
	font-size:16px;
	color:#fff;
	text-indent: 2em;
	padding: 8px 5px;
}
.pri_box{
	// background:url('../assets/images/b03.png');
	padding-top:20px;
	box-sizing:border-box;
}
.pri_box .title{
    font-size: 18px;
    color: #000000;
}
.pri_box i{
	font-size: 23px;
	font-weight: 400;
	color: #FF0000;
	line-height: 47px;
    padding-left: 45px;
    padding-right: 10px;
}
.pri_box  .price{
	font-size: 30px;
	font-weight: bold;
	color: #FF0000;
	position: relative;
	
}
.price_box{
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
    .box_left{
        width: 105px;
        height: 24px;
        padding-right: 15px;
        display: flex;
        justify-content: space-between;
        p{
            display: inline-block;
            font-size: 18px;
            line-height: 18px;
            color: #000000; 
            width: 64px;

        }
    }
    .box_right{
        p{
            font-size: 16px;
            line-height: 20px;
        }
    }
}
.box_ti{
   margin-top:35px;
   display: flex;
   align-items: flex-start;
}
.box_ti .ti_left{
    display: flex;
    justify-content: space-between;
    width: 70px;
    padding-right: 50px;
}
.ti_left p{
    font-size: 18px;
	color: #000000;
	line-height: 18px;
}
.box_ti .numbers{
    width: 69px;
	height: 25px;
	border: 1px solid #000000;
	font-size: 13px;
	font-weight: 300;
	color: #000000;
	line-height: 30px;
    padding-left: 6px;
}
.box_fenlei{
	display: inline-block;
}
.box_fenlei span{
    width: 112px;
	height: 32px;
	display: inline-block;
	border: 1px solid #CFCDCD;
	font-size: 13px;
	font-weight: 300;
	color: #717171;
	text-align: center;
	line-height: 32px;
	margin-right:19px;
	cursor: pointer;
}
.box_ti .numbers{
    width: 51px;
	height: 31px;
	border: 1px solid #CFCDCD;
	font-size: 13px;
	font-weight: 300;
	text-align: center;
	color: #717171;
	line-height: 30px;
}
.box_bottom{
	margin-top:30px;
}
.box_bottom a{
	width: 133px;
	height: 36px;
	background: #FF7F02;
	font-size: 15px;
	font-weight: 300;
	text-align: center;
	color: #FFFFFF;
	display: inline-block;
	line-height: 36px;
	margin-right:9px;
}
.box_bottom a:nth-of-type(2){
    color: #fff;
    background:#FF7F02;
    border:1px solid #FF7F02;
}

}

</style>
